import React, { useState } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";

export default function SignUpForm() {
  const [user, setUser] = useState({
    name: "",
    email: "",
  });

  const [sent, SetSent] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleUser = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    console.log(user);
  };

  const SignUpFunctn = async (attributes, email, listIds) => {
    const response = await axios.post(
      `https://api.brevo.com/v3/contacts`,
      {
        attributes,
        email,
        listIds,
      },
      // {
      //   headers: {
      //     "api-key":
      //       "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",
      //   },
      //   "Access-Control-Allow-Origin": "*",
      //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      // }
      {
        headers: {
          "api-key":
            "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",
        },
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      }
      // {
      //   headers: {
      //     Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiN2I5OGMzZmI1OTEzODMxZDM0MWFiMTZkYWFjMDRlMTY4YTMyNDZhMWQ4ZTFmZWM1M2Y4NTU0M2I0OTZlZjk3MDdhOWE4OThjNmRmZjYyMTQiLCJpYXQiOjE2OTc5MDU1MjguOTA2NzEsIm5iZiI6MTY5NzkwNTUyOC45MDY3MTIsImV4cCI6NDg1MzU3OTEyOC45MDIyMzEsInN1YiI6IjY4MDM3MyIsInNjb3BlcyI6W119.WnNt9BzrRDyX4um4pQwxscS4PbCqwqRCM5xhLjpzfhBb9QdVDiQ_isWeceXpH5PO461iL6I3flFmr8jNOeWOLvtlRaYdYgUqwN9BfZeF5sjz8Kdv44m4g4oF2biuuk0SRxb7aSi8Zh69t0hBHe1NkZnxN5sUtFHRVyN_ubW0d9abdPEGOlDGwnl0KcNVqe6Th3tPDkidOwZdXbvoCmM4LP6OveUFQXBsqr-tuzRLtDoTCsDIHMJbuCX5bXf4C8o4hZRY15bIbZXvul9riHd_uNPHp_HXNYhBf2lwkIXAkISZgauq4TveiptFJlnyDcu4RPERoxvYXiy_V0blwaPyNjA3yUaffWtC0t-mevrwGgddLQD2xOWpy_nI1iNAIwmixgwiQM8L0ZuwdtYKTP0R2yKuU9AUjXVsCGSKalp4b20ssMw4Iy49Wt_Mdi_Ny-f-2MbO-3SmmQrUDdWp2fu2EHveFFqgyv72W0tmK5dbvVXsPGd5hbzWi-vmvEycPsWd_ekX8zwnFCExeNB9ckRL8pzJTRW_zqjYI3JcKq-7Tpo8fHIikeW9uz0VrQTPeh7AcX7qopsQyGCIOp4lZaIm5GtznrT6Bo8H0lhcoBCRNAbxzohEGzgnV3auPW1b_4Jn5XtmXmttW3c6JOHdap91a1vEtNXfP3Jb6AkpGBj8k8g`,
      //   },
      //   "Access-Control-Allow-Origin": "*",
      //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      // }
    );

    console.log(response.data);
  };

  const handleSignUp = async () => {
    setLoading(true);
    setError(false);
    try {
      if (user.email === "" || user.name === "") {
        setError("All fields are required");
        setLoading(false);
      } else {
        await SignUpFunctn({ FIRSTNAME: user.name }, user.email, [7]);
        SetSent(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };
  return (
    <>
      {sent ? (
        <div style={{ padding: "5%" }}>
          <Icon
            icon="ph:seal-check"
            width="200px"
            style={{ color: "orange" }}
          />
          <h2 style={{ color: "white" }}>
            Submission successful !, See you soon !
          </h2>
        </div>
      ) : (
        <div className="form">
          {error ? (
            <>
              <div
                style={{
                  background: "red",
                  padding: "2%",
                  borderRadius: "10px",
                  margin: "2%",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {" "}
                <Icon icon="ooui:error" style={{}} />
                <p style={{ color: "white" }}>{error}</p>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="form-box">
            <input
              name="name"
              placeholder=" Full Name"
              className="form-input"
              onChange={handleUser}
              defaultValue={user.name}
              type="text"
              required
            />
          </div>
          <div className="form-box">
            <input
              name="email"
              placeholder="Email Address"
              className="form-input"
              onChange={handleUser}
              defaultValue={user.email}
              type="email"
              required
            />
          </div>
          {loading ? (
            <>
              <Icon
                icon="line-md:loading-twotone-loop"
                width="70px"
                style={{ color: "orange" }}
              />
            </>
          ) : (
            <>
              <button className="btn" onClick={handleSignUp}>
                Sign Up
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
}
