import SignUpForm from "../Components/SignUp-Form/SignUpForm";

import Navbar from "../Components/Navbar/Navbar";

export default function Home() {
  //   animation.current.add({
  //     targets: ".event-title",
  //     scaleY: 1.5,
  //     duration: 3000,
  //     keyframes: [
  //       { translateY: -40 },
  //       { translateX: 250 },
  //       { translateY: 40 },
  //       { translateX: 0 },
  //       { translateY: 0 },
  //     ],
  //   });
  return (
    <div className="bg-home">
      <Navbar />
      {/* <div className="event-signup-header">
        <div className="event-signup-name">
          <img src={Afrosonic} alt="" className="event-signup-title" />
        </div>
      </div> */}
      {/* <div>
        <div className="item-two"></div>
        <div className="item-three"></div>
      </div> */}
      {/* <div className="item-one"></div>
      <div className="item-two"></div>
      <div className="item-three"></div> */}
      {/* 
      <button className="btn">
        <p>Sign Up</p>
      </button> */}
      <div className="sign-upform">
        <SignUpForm />
      </div>
    </div>
  );
}
