import { Icon } from "@iconify/react";
import { useRef } from "react";
import "./Navbar.styles.css";
import { Link } from "react-router-dom";
import { Box, Container } from "@mui/material";
import Logo from "../../assets/images/logo-11.svg";

const Navbar = () => {
  const navRef = useRef();

  const ShowNav = () => {
    navRef.current.classList.toggle("mobile");
  };

  return (
    <div className="nav-bar">
      <Container>
        <div className="nav">
          <Box className="logobox">
            <Link to="/">
              <img src={Logo} alt="LOGO" className="logo" />
            </Link>
          </Box>

          <div className="Nav-Items" ref={navRef}>
            <button className="hamburger-btn" onClick={ShowNav}>
              <Icon icon="clarity:times-line" className="hamburger-icon" />
            </button>
            <h6 className="nav-links">
              <Link
                to="/signup"
                style={{
                  color: "none",
                  textDecoration: "none",
                  textAlign: "center",
                  fontSize: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="link"
              >
                Register
              </Link>
            </h6>
            {/* <h6 className="nav-links">Sign Up</h6> */}
            <h6 className="nav-links">Gallery</h6>
          </div>

          <button className="hamburger-btn" onClick={ShowNav}>
            <Icon icon="quill:hamburger" className="hamburger-icon" />
          </button>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
