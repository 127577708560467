import { Button, Box, Stack } from "@mui/material";
import React from "react";
import "./HeroSlide.style.css";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";

export default function HeroSlide1(props) {
  return (
    <div>
      <Box className="hero-slide">
        <Navbar />
        <Box className="box-info">
          <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              size="large"
              component={Link}
              to="/signup"
            >
              Sign Up
            </Button>
            <Button variant="outlined">Pre Save The Ep</Button>
          </Stack>
        </Box>
      </Box>
    </div>
  );
}
