import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import { Link } from "react-router-dom";
// import Afrosonic from "../assets/images/Afrosonicpng.png";
// import anime from "animejs/lib/anime.es";
// import Logo from "../assets/images/logo-11.svg";
// import Navbar from "../Components/Navbar/Navbar";
import HeroSlide1 from "../Components/HeroSlides/HeroSlide1";
// import HeroSlide2 from "../Components/HeroSlides/HeroSlide2";

export default function Home() {
  // const animation = useRef(null);
  // useEffect(() => {
  //   animation.current = anime({
  //     direction: "alternate",
  //     targets: ".event-name",
  //     keyframes: [
  //       { translateY: -20 },
  //       { translateY: 0 },
  //       { translateY: -20 },
  //       { translateY: 0 },
  //     ],
  //     delay: 0,
  //     duration: 4000,
  //     easing: "easeInOutSine",
  //     loop: true,
  //     filter: [
  //       "drop-shadow(12px 12px 7px rgb(255, 157, 0)))",
  //       "drop-shadow(12px 12px 7px rgb(212, 255, 0))",
  //     ],
  //   });
  //   animation.current = anime({
  //     targets: ".item-one",
  //     keyframes: [
  //       { translateY: -20 },
  //       { translateY: 0 },
  //       { translateY: -20 },
  //       { translateY: 0 },
  //       { translateY: -20 },
  //       { translateY: 0 },
  //     ],
  //     delay: 0,
  //     duration: 5000,
  //     easing: "easeInOutSine",
  //     loop: true,
  //   });

  //   animation.current = anime({
  //     targets: ".item-two, .item-three",

  //     delay: 0,
  //     duration: 4000,
  //     easing: "easeInOutSine",
  //     loop: true,
  //     filter: [
  //       "drop-shadow(12px 12px 7px rgb(255, 157, 0)))",
  //       "drop-shadow(12px 12px 7px rgb(212, 255, 0))",
  //       "drop-shadow(12px 12px 7px rgb(221, 0, 255))",
  //     ],
  //   });
  // }, []);

  //   animation.current.add({
  //     targets: ".event-title",
  //     scaleY: 1.5,
  //     duration: 3000,
  //     keyframes: [
  //       { translateY: -40 },
  //       { translateX: 250 },
  //       { translateY: 40 },
  //       { translateX: 0 },
  //       { translateY: 0 },
  //     ],
  //   });
  return (
    <div className="" style={{ background: "black" }}>
      <HeroSlide1 />

      <div className="logo-box">
        {/* <div className="logo">
          <img src={Logo} alt="logo-img" style={{ cursor: "pointer" }} />
        </div> */}
      </div>
      {/* <div className="event-header">
        <div className="event-name">
          <img src={Afrosonic} alt="" className="event-title" />
        </div>
      </div> */}

      {/* <center>
        <button className="btn">
          <Link
            to="/signup"
            style={{
              color: "none",
              textDecoration: "none",
              textAlign: "center",
              fontSize: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="link"
          >
            <p className="btn-text">Sign Up</p>
          </Link>
        </button>
      </center> */}
    </div>
  );
}
